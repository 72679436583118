.navbar {
    display: flex;
    justify-content: center; /* Centrare il logo orizzontalmente */
    align-items: center;
    padding: 15px 20px;
    background-color: #1a0033;
    border-bottom: 3px solid #ff00ff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    z-index: 20;
    transition: background-color 0.5s ease;
}

.navbar-logo {
    height: 70px;
    width: auto;
    margin: 0; /* Rimuovere margini inutili */
    filter: drop-shadow(0 0 10px #00ffff);
    transition: transform 0.3s ease, filter 0.3s ease;
}

.navbar-logo:hover {
    transform: scale(1.05);
    filter: drop-shadow(0 0 15px #ff00ff);
}


.bottom-navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to right, #1a0033, #33005e);
    border-top: 3px solid #00ffff;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.7);
    animation: navbar-glow 3s infinite alternate;
    transition: background 0.5s ease;
}

.bottom-navbar a {
    flex-grow: 1;
    text-align: center;
    padding: 12px 20px;
    margin: 0 5px;
    font-size: 14px;
    font-family: 'Press Start 2P', cursive;
    color: #00ffff;
    background: linear-gradient(45deg, #1a0033, #33005e);
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s ease, transform 0.2s ease;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.bottom-navbar a:hover {
    background: linear-gradient(45deg, #33005e, #00ffff);
    color: #1a0033;
    transform: translateY(-3px);
    box-shadow: 0 0 15px rgba(255, 0, 255, 0.8);
}

.bottom-navbar a:active {
    transform: translateY(0);
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

@keyframes navbar-glow {
    0% {
        background: linear-gradient(to right, #1a0033, #220044);
    }
    100% {
        background: linear-gradient(to right, #220044, #1a0033);
    }
}
