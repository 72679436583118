.leaderboard {
    padding: 20px 10px;
    text-align: center;
    color: #00ffff;
    margin: 0 auto;
    width: 100%; /* Occupare tutto lo spazio orizzontale */
    max-width: none; /* Rimosso il limite */
    background: linear-gradient(to bottom, #1e004d, #33005e);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
    animation: fade-in 1.5s ease;
    position: relative;
    margin-top: 80px; 
    margin-bottom: 80px; 
}

.leaderboard h1 {
    font-family: 'Press Start 2P', cursive;
    font-size: 18px;
    color: #ff00ff;
    margin-bottom: 20px;
    text-shadow: 0 0 10px #00ffff, 0 0 20px #ff00ff;
}

/* Tabella */
.leaderboard-table {
    width: 100%; /* Usa tutta la larghezza */
    border-collapse: collapse;
    margin: 0;
    background-color: #1a0033;
    box-shadow: 0 0 15px rgba(255, 0, 255, 0.5);
}

.leaderboard-table th,
.leaderboard-table td {
    padding: 12px 8px;
    font-family: 'Press Start 2P', cursive;
    font-size: 12px;
    text-align: center;
    color: #00ffff;
    border: 1px solid #33005e;
    background: linear-gradient(45deg, #1a0033, #220044);
    word-wrap: break-word;
}

.leaderboard-table th {
    background-color: #33005e;
    color: #ff00ff;
    text-shadow: 0 0 5px #00ffff;
}

.leaderboard-table tr:nth-child(even) {
    background-color: #220044;
}

@media screen and (max-width: 768px) {
    .leaderboard {
        padding: 10px 5px;
    }

    .leaderboard h1 {
        font-size: 16px;
    }

    .leaderboard-table th,
    .leaderboard-table td {
        font-size: 10px;
        padding: 8px 4px;
    }
}

.leaderboard-table tr:hover {
    background: none;
    color: inherit;
    transform: none;
    transition: none;
}

.leaderboard-container {
    display: flex;
    flex-direction: column;
    max-height: 400px; 
    overflow-y: auto; 
    border: 2px solid #33005e; 
    background-color: #1a0033;
    border-radius: 5px;
    width: 100%; /* Occupare tutta la larghezza disponibile */
}

.leaderboard-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #33005e; 
    color: #ff00ff;
}

.leaderboard-header table {
    width: 100%;
}

.leaderboard-body table {
    width: 100%;
}

.leaderboard-body tr:nth-child(even) {
    background-color: #220044;
}

.leaderboard-body tr:hover {
    background-color: #33005e;
}
