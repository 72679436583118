/* Global.css */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: 'Press Start 2P', cursive;
    background: linear-gradient(to bottom, #33005e, #1a0033);
    color: #00ffff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    animation: background-flicker 8s infinite alternate;
}

/* Assicurati che il contenuto non finisca sotto la navbar */
.page-container {
    padding-bottom: 80px; /* Aggiunge spazio per evitare sovrapposizione con la navbar */
  }
  
  .leaderboard, .rewards-tab {
    margin-top: 10px; /* Riduce lo spazio sopra il contenuto */
    padding-bottom: 80px; /* Aggiunge spazio per evitare sovrapposizione sotto */
    overflow-y: auto; /* Permette di scrollare se necessario */
    max-height: calc(100vh - 160px); /* Assicura che il contenuto non superi l'altezza dello schermo */
  }

  
@keyframes background-flicker {
    0% {
        background: #33005e;
    }
    50% {
        background: #1a0033;
    }
    100% {
        background: #290054;
    }
}
