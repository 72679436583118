/* Notifications.css */

.notification-container {
  position: fixed;
  top: -100px; /* Start off-screen */
  left: 50%;
  transform: translateX(-50%);
  background-color: #1e004d;
  color: #00ffff;
  font-family: 'Press Start 2P', cursive;
  font-size: 14px;
  padding: 15px 25px;
  border-radius: 10px;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease, top 0.5s ease;
  box-shadow: none;
}

.notification-container.show {
  top: 20px; /* Slide in to visible position */
  opacity: 1;
  pointer-events: auto;
  box-shadow: 0 0 15px #00ffff, 0 0 30px #ff00ff, 0 0 45px #00ffff;
  animation: glow-pulse 2s infinite alternate;
}

.notification-container.hide {
  top: -100px; /* Slide out to hide */
  opacity: 0;
  pointer-events: none;
  box-shadow: none;
  animation: none;
}

/* Glow Pulse Animation */
@keyframes glow-pulse {
  from {
    box-shadow: 0 0 15px #00ffff, 0 0 30px #ff00ff, 0 0 45px #00ffff;
  }
  to {
    box-shadow: 0 0 25px #00ffff, 0 0 45px #ff00ff, 0 0 60px #00ffff;
  }
}

/* Optional: Add a fade-in and fade-out effect */
@keyframes fade-in {
  from { opacity: 0; top: -100px; }
  to { opacity: 1; top: 20px; }
}

@keyframes fade-out {
  from { opacity: 1; top: 20px; }
  to { opacity: 0; top: -100px; }
}
