/* Login Page styles - Cyberpunk */
.login-page {
    display: flex;
    justify-content: center; /* Centra il pulsante orizzontalmente */
    align-items: center; /* Centra il pulsante verticalmente */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('./GifLoginPage.gif'); /* Usa un'immagine cyberpunk */
    background-size: cover; /* Adatta lo sfondo */
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    padding: 0;
    z-index: 10; /* Sovrapposizione */
}

/* Pulsante stile neon */
.button {
    font-family: 'Press Start 2P', cursive;
    font-size: 16px;
    padding: 20px 30px;
    background-color: #000;
    color: #00ffff; /* Blu neon */
    border: 2px solid #ff007f;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px #8000ff; /* Effetto neon */
}

.button:hover {
    background-color: #ff007f;
    color: #000;
    transform: scale(1.2);
    box-shadow: 0 0 20px #00ffff;
}
