/* File: src/components/Referrals.css */

/* Globale: box-sizing e reset dei margini/padding */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Contenitore principale */
.referrals-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  padding-bottom: 140px;
  text-align: center;
  min-height: 100vh;
  width: 100%;
  overflow: hidden; /* Rimosso scroll a livello principale */
  background: linear-gradient(to bottom, #1e004d, #33005e);
  border-radius: 15px;
  animation: fade-in 1.5s ease;
}

/* Contenitore interno */
.referrals-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 0 10px;
  gap: 20px;
  overflow-y: auto; /* Scroll solo nel contenitore interno */
}

/* Selettori con font-family comune */
.section-title,
.referral-code,
.invited-users,
.task-button,
.reward-item {
  font-family: 'Press Start 2P', cursive;
}

/* Sezioni */
.section {
  width: 100%;
  max-width: 800px;
  margin: 10px 0;
  padding: 20px;
  border: 2px solid #00ffff;
  border-radius: 10px;
  box-shadow: 0 0 15px #00ffff;
  background-color: #1a0033;
  overflow: hidden; /* Disabilitato lo scroll per le sezioni */
}

.section-title {
  font-size: 14px;
  color: #00ffff;
  margin-bottom: 15px;
  white-space: nowrap;
}

.referral-code {
  font-size: 12px;
  color: #ff00ff;
  word-break: break-all;
}

.invited-users {
  font-size: 12px;
  color: #00ffff;
  margin-top: 15px;
}

.referral-input {
  width: 80%;
  max-width: 300px;
  padding: 8px;
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: 10px; /* Added margin-bottom for spacing */
  border: 2px solid #00ffff;
  border-radius: 5px;
  background-color: #33005e;
  color: #ff00ff;
}

/* Pulsanti */
.task-button {
  font-size: 12px;
  padding: 8px 15px;
  background-color: #1a0033;
  color: #ff00ff;
  border: 2px solid #00ffff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  box-shadow: 0 0 15px #00ffff;
}

.task-button:hover {
  background-color: #33005e;
  color: #00ffff;
  box-shadow: 0 0 20px #ff00ff;
}

/* Lista delle ricompense */
.rewards-list {
  list-style: none;
  padding: 0;
  width: 100%;
}

.reward-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  font-size: 10px;
  color: #fff;
  word-break: break-word;
  gap: 5px;
}

.reward-item span {
  text-align: left;
  flex: 1;
}

.task-button {
  flex-shrink: 0;
  margin-left: 10px;
}

.reward-unavailable {
  color: red;
}

/* Scrollbar */
.referrals-page::-webkit-scrollbar {
  width: 8px;
}

.referrals-page::-webkit-scrollbar-track {
  background: #33005e;
  border-radius: 4px;
}

.referrals-page::-webkit-scrollbar-thumb {
  background-color: #00ffff;
  border-radius: 4px;
}

/* Animazione */
@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Media Queries */
@media (max-width: 600px) {
  .section-title {
    font-size: 12px;
  }

  .referral-code {
    font-size: 10px;
  }

  .referral-input {
    width: 90%;
    max-width: 250px;
  }

  .task-button {
    font-size: 10px;
    padding: 6px 12px;
  }

  .reward-item {
    font-size: 8px;
    gap: 5px;
  }

  .reward-item button {
    margin-top: 5px;
  }
}
