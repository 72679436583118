/* Layout.css */
.app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(to right, #1e004d, #33005e);
}

.main-content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    animation: content-glow 5s infinite alternate;
    background: linear-gradient(to right, #220044, #33005e);
}

@keyframes content-glow {
    0% {
        box-shadow: 0 0 5px #ff00ff;
    }
    100% {
        box-shadow: 0 0 20px #00ffff;
    }
}