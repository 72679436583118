/* Dashboard.css */
.dashboard {
  padding: 20px;
  text-align: center;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 15px;
  overflow-y: auto;
  height: 100%;
  animation: fade-in 1.5s ease;  
  background: linear-gradient(to bottom, #1e004d, #33005e);
}

.tab-button {
  font-family: 'Press Start 2P', cursive;
  font-size: 14px;
  padding: 12px 20px;
  background-color: #33005e;
  color: #ff00ff;
  border: 2px solid #00ffff;
  cursor: pointer;
  margin: 0 10px;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px #00ffff;
}

.tab-button.active {
  background-color: #00ffff;
  color: #33005e;
  box-shadow: 0 0 25px #ff00ff;
}

.tab-button:hover:not(.active) {
  background-color: #1a0033;
  box-shadow: 0 0 15px #00ffff;
  transform: scale(1.1);
}

.user-info {
  margin-top: 20px;
  color: #00ffff;
  animation: profile-hover 2s infinite alternate;
}

.user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Rendi l'immagine tonda */
  filter: drop-shadow(0 0 10px #00ffff);
  transition: box-shadow 0.3s ease;
}

.user-avatar:hover {
  box-shadow: 0 0 20px #00ffff, 0 0 30px #ff00ff;
}

.task-button {
  font-family: 'Press Start 2P', cursive;
  font-size: 12px;
  padding: 8px 15px;
  background-color: #1a0033;
  color: #ff00ff;
  border: 2px solid #00ffff;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.task-button:hover {
  background-color: #33005e;
  color: #00ffff;
  box-shadow: 0 0 15px #ff00ff;
}

.logout-button {
  font-family: 'Press Start 2P', cursive;
  font-size: 12px;
  padding: 8px 15px;
  background-color: #1a0033;
  color: #ff00ff;
  border: 2px solid #00ffff;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #33005e;
  color: #00ffff;
  box-shadow: 0 0 15px #ff00ff;
}
.daily-login-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tre colonne */
  grid-template-rows: auto auto auto; /* Tre righe */
  gap: 10px;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
}

.daily-login-day:nth-child(1) {
  grid-column: 1; /* Prima colonna, prima riga */
  grid-row: 1;
}

.daily-login-day:nth-child(2) {
  grid-column: 2; /* Seconda colonna, prima riga */
  grid-row: 1;
}

.daily-login-day:nth-child(3) {
  grid-column: 3; /* Terza colonna, prima riga */
  grid-row: 1;
}

.daily-login-day:nth-child(4) {
  grid-column: 2; /* Seconda colonna, seconda riga (Day 7 centrato) */
  grid-row: 2;
}

.daily-login-day:nth-child(5) {
  grid-column: 1; /* Prima colonna, terza riga */
  grid-row: 3;
}

.daily-login-day:nth-child(6) {
  grid-column: 2; /* Seconda colonna, terza riga */
  grid-row: 3;
}

.daily-login-day:nth-child(7) {
  grid-column: 3; /* Terza colonna, terza riga */
  grid-row: 3;
}


.daily-login-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.daily-login-day {
  font-family: 'Press Start 2P', cursive;
  font-size: 12px;
  padding: 8px 15px;
  background-color: #1a0033;
  color: #ff00ff;
  border: 2px solid #00ffff;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.daily-login-day.active {
  background-color: #33005e;
  color: #00ffff;
  box-shadow: 0 0 15px #ff00ff;
}

.daily-login-day:disabled {
  background-color: #482850;
  color: #ff00ff;
  cursor: not-allowed;
}

.daily-login-day:hover:not(:disabled) {
  transform: scale(1.1);
  box-shadow: 0 0 10px #33005e;
}
